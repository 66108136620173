import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OfferBranchesComponent } from './offer-branches/offer-branches.component';
import { OfferQRCodeComponent } from './offer-qr-code/offer-qr-code.component';
import { OffersService } from '../../../features/panel/services/offers.service';
import { Offer } from '../../../features/panel/models/offers';
import Swal from 'sweetalert2';
import { ConfigService } from '../../../helpers/config.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { RedeemOfferComponent } from './redeem-offer/redeem-offer.component';
import { SocketEventType, SocketService } from '../../../services/socket.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styles: ``
})
export class OfferDetailsComponent implements OnInit {

  @Output() close = new EventEmitter(false);
  @Output() OfferChanged = new EventEmitter(false);
  @Input() selectedId!: string;
  fav_loading: boolean = false;
  dataLoading: boolean = false;
  OfferDeatils!: Offer | null

  subscribtions: Subscription[] = [];
  DetailsSubscription!: Subscription
  constructor(private _dialog: MatDialog,
    private _config: ConfigService,
    private _authenticationService: AuthenticationService,
    private _offerService: OffersService,
    private _socket: SocketService
  ) {

  }


  ngOnInit(): void {
   this.subscribtions.push(this._offerService.refreshDetails.subscribe(data => {
      if (data) {
        this.GetOfferDetails();
        this._offerService.refreshDetails.next(false);
      }
    }))
    if (this.selectedId) {
      this.GetOfferDetails();
      this.subscribtions.push(
        this._socket.getDataStream(SocketEventType.Redeemed_Offers).subscribe((result: any) => {
          if (this.selectedId == result?.data?.offer_id) {
            this.GetOfferDetails()
          }
        })
      )
    }
  }

  // ngOnChanges(changes: any): void {
  //   //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //   //Add '${implements OnChanges}' to the class.
  //   if (changes.selectedId && this.selectedId) {
  //     this.GetOfferDetails()
  //     this.subscribtions.push(
  //       this._socket.getDataStream(SocketEventType.Redeemed_Offers).subscribe((result: any) => {
  //         if (this.selectedId == result?.data?.offer_id) {
  //           this.GetOfferDetails()
  //         }
  //       })
  //     )
  //   }
  // }

  OnViewBranches() {

    const dialogRef = this._dialog.open(OfferBranchesComponent, {
      width: "540px",
      data: this.selectedId
    })
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
      }
    })
  }

  OnViewQR_Code(data: any) {
    data.id = this.selectedId;
    data.rate = this.OfferDeatils?.my_rate;
    const dialogRef = this._dialog.open(OfferQRCodeComponent, {
      width: "540px",
      data: data
    })
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.GetOfferDetails()
      }
    })
  }

  GetOfferDetails() {
    this.dataLoading = true;
    this.DetailsSubscription ? this.DetailsSubscription.unsubscribe() : null;
    this.DetailsSubscription = this._offerService.GetOfferDetails(this.selectedId).subscribe({
      next: (res: any) => {
        this.dataLoading = false;
        this.OfferDeatils = res.data;
        // this.OfferDeatils.offer_end = true;
        // this.OfferDeatils.user_use_offer = false;
      },
      error: (err: any) => {
        this.dataLoading = false;
        this.close.emit(true);
      }
    })
  }


  OnRedeemOffer() {
    const dialogRef = this._dialog.open(RedeemOfferComponent, {
      width: "540px",
      data: this.OfferDeatils?.offer_id
    })
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.GetOfferDetails()
        if (res.view_QR) {
          this.OnViewQR_Code(res.data);
        }
      }
    })

  }


  OnChangeFavoriteOffer() {
    Swal.fire({
      title: this._config._translate.instant('swalMsg.Areyousure'),
      text: '',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this._config._translate.instant('swalMsg.Yes'),
      cancelButtonText: this._config._translate.instant('swalMsg.No')
    }).then((result) => {
      if (result.value) {
        this.fav_loading = true;
        let model = { offer_id: this.selectedId }
        this._offerService.OnChangeFevorateoffers(model).subscribe({
          next: (res: any) => {
            this.fav_loading = false;
            this.OfferDeatils!.favorite = !this.OfferDeatils?.favorite;
          },
          error: (err: any) => {
            this.fav_loading = false;

          }
        })
      }
    });


  }

  ngOnDestroy(): void {
    this.DetailsSubscription ? this.DetailsSubscription.unsubscribe() : null;
    this.subscribtions.forEach(element => {
      element.unsubscribe();
    });
  }
}
